import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

const state = {
  sidebarShow: "responsive",
  sidebarMinimize: false,
  isLoggedIn: !!localStorage.getItem("token"),
  auth: {},
};

const mutations = {
  toggleSidebarDesktop(state) {
    const sidebarOpened = [true, "responsive"].includes(state.sidebarShow);
    state.sidebarShow = sidebarOpened ? false : "responsive";
  },
  toggleSidebarMobile(state) {
    const sidebarClosed = [false, "responsive"].includes(state.sidebarShow);
    state.sidebarShow = sidebarClosed ? true : "responsive";
  },
  set(state, [variable, value]) {
    state[variable] = value;
  },
  dataUser(state, auth) {
    state.isLoggedIn = true;
    state.auth = auth;
  },
  removeUser(state) {
    state.isLoggedIn = false;
    state.auth = {};
  },
  loginUser(state) {
    state.isLoggedIn = true;
  },
  logoutUser(state) {
    state.isLoggedIn = false;
    state.auth = {};
  },
};

export default new Vuex.Store({
  state,
  mutations
})