import Vue from "vue";
import Router from "vue-router";

const TheContainer = () => import("@/containers/TheContainer.vue");
const Dashboard = () => import("@/views/Dashboard.vue");
const Login = () => import("@/views/Account/Login");

const KategoriLayananComponent = () => import("@/views/Admin/kategori_layanan/Index");
const DaftarLayananComponent = () => import("@/views/Admin/daftar_layanan/Index");
const UserProfileComponent = () => import("@/views/Profile/Index");

Vue.use(Router);

export default new Router({
  mode: "hash", // https://router.vuejs.org/api/#mode
  linkActiveClass: "active",
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes(),
});

function configRoutes() {
  return [
    {
      name: "login",
      path: "/login",
      component: Login,
      meta: { auth: false, pageType: "auth" },
    },
    {
      path: "/",
      redirect: "/dashboard",
      name: "Home",
      component: TheContainer,
      meta: { auth: true },
      children: [
        {
          path: "dashboard",
          name: "Dashboard",
          component: Dashboard,
          meta: { auth: true },
        },
        // {
        //   path: "/dashboard",
        //   name: "Operator Dashboard",
        //   component: OperatorDashboard,
        //   meta: { auth: true },
        // },
        {
          path: "/user-profile",
          name: "Profile Pengguna",
          component: UserProfileComponent,
          meta: { auth: true },
        },
        {
          path: "admin",
          redirect: "/admin/kategori-layanan",
          name: "Admin",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              name: "Kategori Layanan",
              path: "/admin/kategori-layanan",
              component: KategoriLayananComponent,
              meta: { auth: true },
            },
            {
              name: "Daftar Layanan",
              path: "/admin/daftar-layanan",
              component: DaftarLayananComponent,
              meta: { auth: true },
            },
            
          ],
        },
      ],
    },
  ];
}
