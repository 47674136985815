import 'core-js/stable'
import Vue from 'vue'
import App from './App'
import router from './router'
import CoreuiVue from '@coreui/vue'
import { iconsSet as icons } from './assets/icons/icons.js'
import store from './store'
import moment from "moment";
import VueRouter from "vue-router";
import VueAxios from "vue-axios";
import axios from "axios";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import VueIziToast from "vue-izitoast";
import "izitoast/dist/css/iziToast.css";
import vueChocolat from "vue-chocolat";

Vue.use(VueRouter);
Vue.component("vue-chocolat", vueChocolat);
Vue.use(CoreuiVue)
Vue.use(VueAxios, axios);
Vue.use(VueIziToast);
Vue.use(Loading);
Vue.use(vue2Dropzone);

moment.locale("id");
let stringFormat = function(value) {
  // let val = value.replace('.', ',')
  return value.toString().replace(/,<\/?[^>]+>/gi, " ,");
};
let numberFormat = function(value) {
  let val = (value / 1).toFixed(0).replace(".", ",");
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

Vue.prototype.moment = moment;
Vue.prototype.stringFormat = stringFormat;
Vue.prototype.numberFormat = numberFormat;
Vue.prototype.url = "https://api.rudaya.id/public/";

Vue.config.productionTip = false;
Vue.config.performance = true;
Vue.prototype.$log = console.log.bind(console);


Vue.component("auth-page", require("./views/Auth.vue").default);
new Vue({
  el: "#app",
  router,
  store,
  icons,
  template: "<App/>",
  render: (h) => h(App),
  components: {
    App,
  },
}).$mount("#app");
