<template>
<section class="section">
    <router-view></router-view>


</section>
</template>

<script>
import Swal from 'sweetalert2';
import iziToast from 'izitoast';
import store from '../store'
import axios from 'axios';
export default {
    mounted() {
        if (this.$store.state.isLoggedIn) {
            var redirect = '/';

            // if (this.$store.state.auth.level == "Admin"){
            //     redirect = '/admin/dashboard';
            // }
            this.$router.push({
                name: redirect
            });
            window.location = redirect;
        }
    },
    data() {
        return {
            date: new Date,
            auth: {
                username: '',
                password: '',
                has_error: false,
                success: false,
                remember_me: false
            },
            button_loading: false,
            loader: '',
            loginError: false
        }
    },
    methods: {
        Loader() {
            this.loader = this.$loading.show({
                // Optional parameters
                container: this.fullPage ? null : this.$refs.formContainer,
                loader: 'dots',
                color: '#6777ef'

            });
        },
        getUser(token) {
            let uri = this.url +`api/v1/auth/user`;
            this.axios.get(uri, {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            }).then(response => {
                console.log(response.data.user)
                localStorage.setItem('dataUser', JSON.stringify(response.data.user))
                store.commit('dataUser', response.data.user)

            });
        },
        submitLogin() {
            console.log("Login");
            this.Loader();
            this.button_loading = true;
            this.loginError = false;
            axios.post(this.url+'api/v1/auth/login', {
                username: this.auth.username,
                password: this.auth.password,
                remember_me: this.auth.remember_me
            }).then(response => {
                this.loader.hide();
                this.button_loading = false;
                localStorage.setItem('token', response.data.access_token)
                localStorage.setItem('token_expire', response.data.expires_at)

                console.log(response.data.expires_at);
                // login user, store the token and redirect to dashboard
                store.commit('loginUser')
                this.getUser(response.data.access_token);
                // store.commit('dataUser', response.data.user)

                Swal.fire({

                    title: 'Login Berhasil!',
                    text: 'Anda akan di arahkan dalam 3 detik',
                    icon: 'success',
                    timer: 3000,
                    showCancelButton: false,
                    showConfirmButton: false
                });
                setTimeout(() => {
                    this.$router.push({
                        name: 'index'
                    });
                    window.location = '/';
                }, 1000);
            }).catch(error => {
                console.log(error);
                this.loginError = true;
                this.loader.hide();
                this.button_loading = false;
                Swal.fire({

                    title: 'Login Gagal!',
                    text: 'Username atau password salah!',
                    icon: 'error',
                });
            });
        },
        loginFunc() {
            console.log("Login");
            this.Loader();
            this.button_loading = true;
            axios
                .post(this.url+"api/v1/auth/login", {
                    username: this.auth.username,
                    password: this.auth.password
                })
                .then(res => {
                    this.loader.hide();
                    this.button_loading = false;
                    // save token to vuex
                    console.log(res.data.access_token);
                    this.$store.dispatch("saveToken", {
                        token: res.data.access_token
                    });
                    // get user data, store in vuex
                    this.$store.dispatch("fetchauth");
                    // redirect

                    Swal.fire({

                        title: 'Login Berhasil!',
                        text: 'Anda akan di arahkan dalam 3 Detik',
                        icon: 'success',
                        timer: 3000,
                        showCancelButton: false,
                        showConfirmButton: false
                    });
                    setTimeout(() => {
                        // const redirectTo = this.$auth.user().level === 'Admin' ? '/admin' : '/transaksi';
                        this.$router.push({
                            name: 'index'
                        });
                        window.location = '/';
                    }, 1000);

                })
                .catch(e => {
                    console.log(e);
                    this.loader.hide();
                    this.button_loading = false;
                    Swal.fire({

                        title: 'Login Gagal!',
                        text: 'Username atau password salah!',
                        icon: 'error',
                    });
                });
        },
        // login() {
        //     this.Loader();
        //     this.button_loading = true;
        //     var redirect = this.$auth.redirect()
        //     var app = this
        //     this.$auth.login({
        //         data: {
        //             username: app.auth.username,
        //             password: app.auth.password
        //         },
        //         success: function () {
        //             this.loader.hide();
        //             // handle redirection
        //             app.auth.has_error = false;
        //             app.auth.success = true;
        //             app.button_loading = true;

        //             Swal.fire({

        //                 title: 'Login Berhasil!',
        //                 text: 'Anda akan di arahkan dalam 3 Detik',
        //                 type: 'success',
        //                 timer: 3000,
        //                 showCancelButton: false,
        //                 showConfirmButton: false
        //             });
        //             setTimeout(() => {
        //                 // const redirectTo = this.$auth.user().level === 'Admin' ? '/admin' : '/transaksi';
        //                 const redirectTo = '/';
        //                 this.$router.push({
        //                     name: redirectTo
        //                 })
        //                 // window.location = '/admin';
        //             }, 1000);

        //             // setTimeout(() => {
        //             //     const redirectTo =  this.$auth.user().level === 'Admin' ? '/admin' : '/transaksi';
        //             //     // this.$router.push(redirectTo)
        //             //     window.location= '/admin';
        //             // }, 1000);
        //         },
        //         error: function () {
        //             app.button_loading = false;
        //             app.auth.has_error = true
        //             this.loader.hide();
        //             Swal.fire({

        //                 title: 'Login Gagal!',
        //                 text: 'Username atau password salah!',
        //                 type: 'error',
        //             });
        //         },
        //         rememberMe: true,
        //         fetchUser: true
        //     })
        // }
    },
}
</script>
